<template>
  <div class="app-upload-image">
    <label v-if="!value" @click="type === 'video' ? fileUpload() : null">
      <span class="content">
        <upload class="icon-upload" />
        <span class="icon-btn">
          <add />
        </span>
        <input v-if="type === 'image'" type="file" accept="image/png, image/jpeg, image/gif" @change="fileUpload">
      </span>
    </label>
    <div class="img" v-else>
      <img :src="value" alt="Agency media">
      <div class="btn">
        <app-button theme="primary" icon="download" size="round" @click="type === 'video' ? fileUpload() : $refs.file.click()"></app-button>
        <app-button theme="warning" icon="remove" size="round" @click="remove"></app-button>
      </div>
    </div>
    <input type="file" ref="file" accept="image/png, image/jpeg" @change="fileUpload">
  </div>
</template>

<script>
import upload from '@/assets/img/upload.svg?inline';
import add from '@/assets/img/add.svg?inline';

export default {
  name: 'app-upload-image',
  components: {
    upload,
    add,
  },
  props: {
    value: {
      type: String,
    },
    type: {
      type: String,
      validator: (val) => ['image', 'video'].indexOf(val) !== -1,
      default: 'image',
    },
  },
  methods: {
    fileUpload(event) {
      if (event) {
        this.$emit('upload', event.target.files[0]);
      } else {
        this.$emit('upload');
      }
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="sass" scoped>
.app-upload-image
  display: flex
  width: 100%
  height: 100%
  min-width: 180px
  min-height: 110px

  label
    cursor: pointer
    background-color: $off-white
    border: 1px dashed $line
    border-radius: 4px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 100%

    &:hover
      border-color: $primary

      .icon-upload path
        fill: $primary

    .content
      position: relative

      .icon-upload
        width: 38px
        height: 32px

      .icon-btn
        position: absolute
        background-color: $primary
        top: 12px
        left: 30px
        height: 32px
        width: 32px
        border-radius: 50%
        box-shadow: 0 0 0 2px $off-white

        svg
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          height: 14px
          width: 14px

  input[type="file"]
    position: absolute
    left: 0
    top: 0
    width: 0
    height: 0

  .img
    display: flex
    cursor: pointer
    width: 100%
    height: 100%
    position: relative

    img
      border-radius: 4px
      width: 100%
      height: 100%
      object-fit: cover

    &:hover
      img
        opacity: 0.5
        transition: 0.3s ease-in-out

      .btn
        visibility: visible
        opacity: 1
        transition: 0.3s ease-in-out

    .btn
      position: absolute
      display: flex
      visibility: hidden
      opacity: 0
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      transition: 0.3s ease-in-out

      > button + button
        margin-left: 8px
</style>
