<template>
  <div class="indicators hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>
          Carte des Petites Annonces -
          <slot v-if="agency">{{ agency.name }}</slot>
        </h1>
      </div>
      <div class="cell shrink"></div>
    </div>
    <!-- BODY / FORMULAIRE -->
    <div class="body">
      <div class="card">
        <div class="legend">
          <div>
            <span class="number" v-if="postalCodes">
              {{ postalCodes.length }}
            </span>
            <span class="label">Communes sur le secteur</span>
          </div>
          <div>
            <span class="label">Quota T+M : </span>
            <span class="number">{{ landVersionLeboncoinAgencyQuota }}</span>
            <span class="label">(dont {{ landVersionManualAgencyQuota }} PA manuelles)</span>
          </div>
        </div>
        <div id="map"></div>
        <div class="loading-map" v-if="isLoading">
          <app-spinner />
        </div>

        <div class="legend">
          <div>
            <span class="square bad"></span>
            <span class="number" v-if="badCities">
              {{ badCities.length }}
            </span>
            <span class="label">Communes sans Annonces</span>
          </div>
          <div>
            <span class="square"></span>
            <span class="number" v-if="postalCodes && badCities">
              {{ postalCodes.length - badCities.length }}
            </span>
            <span class="label">Communes avec Annonces T+M</span>
          </div>
        </div>
        <p>
          <small>Mise à jour des données toutes les 48H.</small>
        </p>
        <div class="agency" v-if="agenciesList && agenciesList.length > 1">
          <app-select v-if="agenciesList"
            :value="null"
            v-model="agencyId"
            :options="agenciesList"
            :disabled="agenciesList.length === 0"
            @input="agencyChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import agency from '@/services/api/agency';
import postalCode from '@/services/api/postalCode';
import advertisementApi from '@/services/api/advertisement';
import quotaApi from '@/services/api/quota';

export default {
  name: 'indicators',
  components: {},
  metaInfo: {
    title: 'Vos indicateurs',
  },
  data() {
    return {
      isLoading: null,
      agencies: null,
      agency: null,
      agencyId: null,
      postalCodes: null,
      goodCities: [],
      badCities: [],
      quotas: null,
      landVersionManualAgencyQuota: 0,
      landVersionLeboncoinAgencyQuota: 0,

      agenciesList: null,

      map: null,
      mapIsLoading: true,
      initialMapSettings: {
        center: [2.7195924, 46.9259861],
        zoom: 4.5,
      },
      borders: {
        type: 'FeatureCollection',
        name: 'borders',
        features: [],
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    this.setMap();
    await this.getData();
    await this.getQuotas();
    this.getAgencyQuota();
  },
  methods: {
    // Setup de la carte
    setMap() {
      this.isLoading = false;
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: process.env.VUE_APP_MAPBOX_STYLE_URL,
        center: this.initialMapSettings.center,
        zoom: this.initialMapSettings.zoom,
        minZoom: 0,
        maxZoom: 20,
      });

      // Ajout des éléments de contrôles
      this.map.addControl(new mapboxgl.NavigationControl());

      this.loadBorders();
    },

    // Recupérer toutes les agences, la première devient l'agence par défaut
    async getData() {
      this.isLoading = true;
      try {
        this.agencies = await agency.getAll();
        this.agenciesList = this.agencies.map((ag) => ({
          label: ag.name,
          name: ag.agencyId,
        }));

        if (this.agencies[0]) {
          if (this.agencyId) {
            this.agency = this.agencies.find((ag) => ag.agencyId === this.agencyId);
          } else {
            [this.agency] = this.agencies;
            this.agencyId = this.agency.agencyId;
          }
          this.postalCodes = await postalCode.getByAgency(this.agency.agencyId);

          // Récupération des stats
          const now = new Date();
          const agencyStatistics = await advertisementApi.getStatistics(this.agency.agencyId, this.$dayjs(now).format('YYYY-MM-DD'));

          // Récupération des frontières pour les inclure dans l'objet 'borders' si il y a
          if (this.postalCodes.length > 0) {
            this.borders.features = this.postalCodes
              .map((cp) => {
                const adStat = agencyStatistics.postalCodeAdvertisements.find((stat) => stat.postalCode === cp.postalCodeInseeNumber);
                const landStat = agencyStatistics.postalCodeLandsCount.find((stat) => stat.postalCode === cp.postalCodeInseeNumber);

                // let totalAds = 0;
                let colorBorder = '#bcbec1';
                let PAACount = 0;
                let PAMCount = 0;
                let PATCount = 0;
                let TCount = 0;
                if (adStat) {
                  PAACount = adStat.autoLandAndHouseLogsCount;
                  PAMCount = adStat.manualLogsCount;
                  PATCount = adStat.autoLandLogsCount;
                }

                if (landStat) {
                  TCount = landStat.activeLandCount;
                }

                if (PAMCount === 0) {
                  colorBorder = '#ed8c7c';
                  this.badCities.push(cp);
                }

                const item = cp.borders;
                item.properties = {
                  description: cp.city,
                  title: cp.city,
                  postalCodeInseeNumber: cp.postalCodeInseeNumber,
                  secondaryCityName: Array.isArray(cp.secondaryCityName) ? `Anciennement ${cp.secondaryCityName.join(', ')}` : ' ',
                  PAACount,
                  PAMCount,
                  PATCount,
                  TCount,
                  color: colorBorder,
                };

                return item;
              })
              .sort((a, b) => a.properties.PAMCount - b.properties.PAMCount);
          } else {
            this.borders.features = [];
          }
          this.loadBorders();
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des données.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }

      this.isLoading = false;
    },

    async getQuotas() {
      try {
        this.quotas = await quotaApi.getAll();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des quotas',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    getAgencyQuota() {
      if (this.quotas && this.agency.agencyId) {
        this.landVersionManualAgencyQuota = this.quotas.find((q) => q.agencyId === this.agency.agencyId && q.type === 'LAND_VERSION_MANUAL').value;
        this.landVersionLeboncoinAgencyQuota = this.quotas.find((q) => q.agencyId === this.agency.agencyId && q.type === 'LEBONCOIN_LAND_VERSION').value;
      }
    },

    // Ajout des layers
    loadBorders() {
      if (this.mapIsLoading) {
        this.map.on('load', () => {
          this.mapIsLoading = false;

          // On appelle la création des layers si les frontières existent
          if (this.borders.features.length > 0) {
            this.setLayers();
          }
        });
      } else {
        // On appelle la création des layers si les frontières existent
        this.setLayers();
      }
    },

    // Création des layers pour chaque commune
    setLayers() {
      const mapSource = this.map.getSource('borders');

      if (!mapSource) {
        // Ajout des sources
        this.map.addSource('borders', {
          type: 'geojson',
          data: this.borders,
        });

        // Création du layer de la surface pour chaque commune
        this.map.addLayer({
          id: 'borders-area',
          type: 'fill',
          source: 'borders',
          layout: {},
          paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': 1,
          },
        });

        // Création du layer des frontières pour chaque commune
        this.map.addLayer({
          id: 'borders-borders',
          type: 'fill',
          source: 'borders',
          layout: {},
          paint: {
            'fill-outline-color': '#000',
            'fill-color': 'transparent',
            'fill-opacity': 1,
          },
        });

        // Labels
        this.map.addLayer({
          id: 'polygons-labels',
          type: 'symbol',
          source: 'borders',
          layout: {
            'text-field': [
              'format',
              ['get', 'title'],
              {
                'font-scale': 1.2,
              },
            ],
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'text-offset': [0, 0.5],
            'text-anchor': 'top',
          },
          paint: {
            'text-color': '#055773',
          },
        });

        // Click
        this.map.on('click', 'borders-borders', (e) => {
          let html = '';
          const uniquePostalCodes = new Set();

          e.features.forEach((feature) => {
            if (!uniquePostalCodes.has(feature.properties.postalCodeInseeNumber)) {
              uniquePostalCodes.add(feature.properties.postalCodeInseeNumber);
              html += `
                <h3>${feature.properties.title}</h3>
                <p>
                  (${feature.properties.postalCodeInseeNumber}) ${feature.properties.secondaryCityName}
                </p>
                <div>
                  <div>
                    <span>PA Auto</span>
                    <strong>${feature.properties.PAACount}</strong>
                  </div>
                  <div>
                    <span>PA Manuelles</span>
                    <strong>${feature.properties.PAMCount}</strong>
                  </div>
                  <div>
                    <span>PA Terrain</span>
                    <strong>${feature.properties.PATCount}</strong>
                  </div>
                  <div>
                    <span>Terrains</span>
                    <strong>${feature.properties.TCount}</strong>
                  </div>
                </div>
              `;
            }
          });

          new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(html)
            .addTo(this.map);
        });
      } else {
        mapSource.setData({
          type: 'FeatureCollection',
          features: this.borders.features,
        });
      }

      // On centre la carte sur l'ensemble des borders
      if (this.borders.features.length > 0) {
        const coordinates = [];

        this.borders.features.forEach((ft) => {
          coordinates.push(...ft.geometry.coordinates[0]);
        });

        const line = turf.lineString(coordinates);
        const bbox = turf.bbox(line);

        this.map.fitBounds(bbox, { padding: 20 });
      } else {
        this.map.flyTo({
          center: this.initialMapSettings.center,
          zoom: this.initialMapSettings.zoom,
          speed: 2,
          essential: true,
        });
      }
    },
    async agencyChange() {
      this.badCities = [];
      this.goodCities = [];
      await this.getData();
      this.getAgencyQuota();
    },
  },
};
</script>

<style lang="sass">
.indicators
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .spinner-container
    @include spinner-container
  .card
    @include card
    position: relative
    padding: 1rem

  .mapboxgl-map
    .mapboxgl-popup-content
      padding: 1.2rem
      max-height: 200px
      overflow-y: scroll
      h3
        margin: 0 0 0.2rem
        font-size: 1rem
        color: #055773
        & ~ h3
          margin-top: 1.2rem
      p
        font-size: 0.6rem
        line-height: 1.25
      > div
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 6px
        margin-top: 10px
        > div
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          gap: 2px
          padding: 10px
          background: #f3f4f9
          border-radius: 4px
          span
            font-size: 0.6rem
          strong
            font-size: 1rem
    .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-bottom-left
      display: none

  #map
    width: 100%
    height: 60vh
  .loading-map
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    top: 76px
    left: 1rem
    right: 1rem
    height: 60vh
    background: rgba(96, 185, 255, 0.5)
    backdrop-filter: blur(2px)

  .agency
    position: absolute
    top: 1rem
    right: -1rem
    display: flex
    align-items: center
    gap: 1rem
    padding: 0.4rem
    min-width: 260px
    font-size: 1.2rem
    .app-select
      width: 240px

  .legend
    display: flex
    gap: 2.5rem
    flex-wrap: wrap
    padding: 1.5rem 0.5rem
    > div
      display: flex
      align-items: center
      gap: 0.4rem
      .square
        display: block
        width: 18px
        height: 18px
        background: #AAAAAA
        &.good
          background: #58D68D
        &.bad
          background: #FF5733
      .city-infos
        padding: 0.5rem 1rem
        border: 1px dashed #0E78D3
        color: #0E78D3
        font-size: 0.8rem
        font-weight: bold
        opacity: 0.9
      .number
        font-size: 1.8rem
      .label
        font-size: 0.9rem
        strong
          font-size: 1rem
          font-weight: bold

</style>
