import axios from 'axios';

const { VUE_APP_API_URL } = process.env;

/**
 * Récupère la liste des codes postaux d'une agence via son ID
 * @param {string} agencyId: ID de l'agence
 * @param {string} search: texte de recherche par code postal ou par nom de ville
 */
const getByAgency = async (agencyId, search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/agencies/${agencyId}/postal-codes`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Ajoute un code postal via le numéro insee
 * @param {string} agencyId: ID de l'agence
 * @param {string} postalCodeInseeNumber: code postal à ajouter
 */
const addOneByAgency = async (agencyId, postalCodeInseeNumber) => {
  try {
    const response = await axios.post(`${VUE_APP_API_URL}/agencies/${agencyId}/postal-codes/${postalCodeInseeNumber}`);
    return response.data.data;
  } catch (error) {
    throw error && error.response ? error.response.data : null;
  }
};

/**
 * Ajout d'une liste de codes postaux via un fichier CSV
 * @param {string} agencyId: ID de l'agence
 * @param {File} file: fichier de codes postaux
 */
const importFileByAgency = async (agencyId, file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(`${VUE_APP_API_URL}/agencies/${agencyId}/postal-codes/import`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error) {
    throw error && error.response ? error.response.data : null;
  }
};

/**
 * Supprime un code postal via le numéro insee
 * @param {string} agencyId: ID de l'agence
 * @param {string} postalCodeInseeNumber: code postal à supprimer
 */
const deleteOneByAgency = async (agencyId, postalCodeInseeNumber) => {
  try {
    const response = await axios.delete(`${VUE_APP_API_URL}/agencies/${agencyId}/postal-codes/${postalCodeInseeNumber}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des codes postaux d'un style via son ID
 * @param {string} styleId: ID du style
 * @param {string} search: texte de recherche par code postal ou par nom de ville
 */
const getByStyle = async (styleId, search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/styles/${styleId}/postal-codes`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des codes postaux d'une règle via son UUID
 * @param {string} regulationId: UUID de la règle
 * @param {string} search: texte de recherche par code postal ou par nom de ville
 */
const getByRegulation = async (regulationId, search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/regulations/${regulationId}/postal-codes`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des codes postaux d'un style via son ID
 * @param {string} search: texte de recherche par code postal ou par nom de ville
 */
const getAll = async (search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/postal-codes`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des codes postaux d'un style via son ID
 * @param {string} search: texte de recherche par code postal ou par nom de ville
 */
const getAllPublic = async (search) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/postal-codes`, {
      params: { ...(search && { search }) },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère un code postal
 * @param {string} postalCodeInseeNumber: code postal à récupérer
 */
const getOne = async (postalCodeInseeNumber) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/postal-codes/${postalCodeInseeNumber}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des codes postaux d'une agence via son ID
 * @param {string} postalCodeInseeNumber: code postal
 * @param {string} search: texte de recherche par code postal ou par nom de ville
 */
const getByPostalCode = async (brandId, postalCodeInseeNumber, radius, latitude, longitude) => {
  try {
    const response = await axios.get(`${VUE_APP_API_URL}/public/brands/${brandId}/city-lands/`, {
      params: {
        ...(postalCodeInseeNumber && { postalCodeInseeNumber }),
        ...(radius && { radius }),
        ...(latitude && { latitude }),
        ...(longitude && { longitude }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
  * Mettre à jour le prix moyen d'un terrain à construire d'un code postal / commune
  * @param {string} editedPostalCode: nouveau code postal
  * @param {string} postalCodeInseeNumber: code postal à ajouter
  */
const update = async (postalCodeInseeNumber, editedPostalCode) => {
  try {
    const response = await axios.put(`${VUE_APP_API_URL}/postal-codes/${postalCodeInseeNumber}`, editedPostalCode);
    return response.data.data;
  } catch (error) {
    throw error && error.response ? error.response.data : null;
  }
};

const postalCode = {};
postalCode.getByAgency = getByAgency;
postalCode.addOneByAgency = addOneByAgency;
postalCode.importFileByAgency = importFileByAgency;
postalCode.deleteOneByAgency = deleteOneByAgency;
postalCode.getByStyle = getByStyle;
postalCode.getByRegulation = getByRegulation;
postalCode.getAll = getAll;
postalCode.getOne = getOne;
postalCode.getByPostalCode = getByPostalCode;
postalCode.getAllPublic = getAllPublic;
postalCode.update = update;

export default postalCode;
