<template>
  <div class="land-prices hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Prix Moyen des Terrains par Ville</h1>
      </div>
      <div class="cell shrink">

      </div>
    </div>

    <!-- CONTENT -->
    <div class="body">
      <div class="grid-x">
        <!-- SEARCH BAR -->
        <div class="cell auto search">
          <app-search
            v-model="search"
            placeholder="Rechercher une commune par nom ou code postal"
            size="auto"
          />
        </div>

        <div class="cell auto">
          <span v-if="cityLandPrices" class="cities-info">{{ cityLandPrices.length }} communes</span>
        </div>

        <div class="cell small-2 search" v-if="isBrandAdmin && agencies">
          <app-select :value="null" v-model="agencyId" :options="agencies" @input="agencyChange" />
        </div>

      </div>

       <!-- TABLE -->
       <div v-if="cityLandPrices">
        <app-table
          :headers="tableHeader"
          :data="cityLandPrices.filter((cityLandPrice) => search === null ? true : cityLandPrice.city.toLowerCase().includes(search.toLowerCase()) || cityLandPrice.postalCodeInseeNumber.split('-')[0].includes(search))"
          :loading="isLoading"
        >
          <template slot="loading">
            <app-spinner />
          </template>
          <template slot="city" slot-scope="{ data }">
            <strong>
              {{ data.city }}
            </strong>
            <br>
            <small v-if="data.secondaryCityName">({{ data.secondaryCityName.join(', ') }})</small>
          </template>
          <template slot="postalCode" slot-scope="{ data }">
            <strong>
              {{ data.postalCodeInseeNumber.split('-')[0] }}
            </strong>
          </template>

          <template slot="inseeNumber" slot-scope="{ data }">
            <strong>
              {{ data.postalCodeInseeNumber.split('-')[1] }}
            </strong>
          </template>
          <template slot="price" slot-scope="{ data }">
            <app-input type="number" v-model="data.price" :disabled=" data.postalCodeInseeNumber !== editableLandPriceCode" />
          </template>
          <template slot="action" slot-scope="{ data }">
            <app-button v-if="data.postalCodeInseeNumber !== editableLandPriceCode" theme="secondary" size="small" @click="editableLandPriceCode = data.postalCodeInseeNumber">Modifier</app-button>
            <div class="actions" v-if="data.postalCodeInseeNumber === editableLandPriceCode">
              <app-button theme="primary" size="small" @click="update(data.postalCodeInseeNumber, data.price)">Enregistrer</app-button>
              <app-button theme="secondary" size="small" @click="editableLandPriceCode = null, data.price = formatPrice(data.landToBuildAveragePrice)">Annuler</app-button>
            </div>
          </template>
          <template slot="empty-table">
            <p>Aucune donnée de disponible</p>
          </template>
        </app-table>
      </div>

    </div>
  </div>
</template>

<script>
import postalCodeApi from '@/services/api/postalCode';
import utils from '@/services/utils/utils';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';

export default {
  name: 'land-prices',

  components: {
  },

  metaInfo: {
    title: 'Prix Moyen des Terrains par Ville',
  },

  data() {
    return {
      // Table
      tableHeader: [
        { label: 'Ville', key: 'city', size: '3' },
        { label: 'Code Postal', key: 'postalCode', size: '2' },
        { label: 'Code Insee', key: 'inseeNumber', size: '2' },
        { label: 'Prix moyen du terrain constructible (€/m2)', key: 'price', size: '3' },
        { label: 'Action', key: 'action', size: 'auto' },
      ],
      isLoading: false,
      agency: null,
      agencies: null,
      agencyId: null,

      me: null,
      isBrandAdmin: false,

      search: null,
      cityLandPrices: null,
      editableLandPriceCode: null,
    };
  },

  created() {

  },

  async mounted() {
    await this.getMe();
    await this.getData();
  },

  methods: {
    async getAgencies() {
      try {
        const agencies = await agencyApi.getAll(this.search);

        if (agencies[0]) {
          if (this.agencyId) {
            this.agency = agencies.find((ag) => ag.agencyId === this.agencyId);
          } else {
            [this.agency] = agencies;
            this.agencyId = this.agency.agencyId;
          }
        }
        const parsedAgencies = utils.formatOptions(
          agencies,
          (o) => o.agencyId,
          (o) => o.name,
          'choose',
        );
        parsedAgencies[0].label = 'Choisir une agence';
        this.agencies = parsedAgencies;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des agences',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
    // Récupération du code postal sélectionné
    async getPostalCode(postalCodeInseeNumber) {
      try {
        const postalCode = await postalCodeApi.getOne(postalCodeInseeNumber);
        this.selectedPostalCode = `${postalCode.city} - ${postalCode.postalCodeInseeNumber.split('-')[0]}`;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du code postal',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    async getData() {
      this.isLoading = true;
      this.cityLandPrices = null;
      await this.getAgencies();

      if (this.agency) {
        const cityLandPrices = await postalCodeApi.getByAgency(this.agency.agencyId);
        this.cityLandPrices = cityLandPrices.map((cityLandPrice, index) => {
          cityLandPrices[index].price = this.formatPrice(cityLandPrice.landToBuildAveragePrice);
          return cityLandPrice;
        });
      }

      this.isLoading = false;
    },

    async agencyChange() {
      await this.getData();
    },

    async update(postalCodeInseeNumber, price) {
      this.editableLandPriceCode = null;
      try {
        await postalCodeApi.update(postalCodeInseeNumber, {
          landToBuildAveragePrice: Number(`${price}00`),
        });
        this.$notification.show({
          text: `Le prix moyen pour le code postal ${postalCodeInseeNumber} a été modifié avec succès !`,
        });
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde du prix moyen.',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },

    formatPrice(price) {
      return price.toString().slice(0, -2);
    },
  },
};
</script>

<style lang='sass' scoped>
.land-prices
  .body
    @include screen

  .header
    @include header

  .row
    @include row

  .price
    @include price

  .search
    padding-bottom: 1rem
    margin-right: 1rem
    button
      margin-left: 1rem

  .cities-info
    display: inline-block
    padding: 10px 15px
    background: $white
    font-size: 70%
    color: $blue-dark
    border-radius: $global-border-radius

  .app-table
    .actions
      display: flex
      gap: 10px

  .filter-container
    display: flex
    align-items: center
    justify-content: center
    margin-right: 1rem
  .filter-btn
    margin-left: 1rem

  .edit-land
    .edit-land-input-container
      margin-bottom: 1rem
      &:first-of-type, &:last-of-type
        margin-top: 1rem

    .edit-land-input-submit-container
      margin-top: 3rem

    .edit-land-label
      font-weight: 500
      margin-bottom: .5rem
      &:first-of-type
        margin-top: .5rem

  .no-registered-company-color
    color: red
</style>
