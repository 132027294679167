import Vue from 'vue';
import ls from 'local-storage';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import DesignSystem from '@/views/DesignSystem.vue';

// Member
import MemberLogin from '@/views/member/MemberLogin.vue';
import MemberLostPassword from '@/views/member/MemberLostPassword.vue';
import MemberResetPassword from '@/views/member/MemberResetPassword.vue';
import MemberSignUp from '@/views/member/MemberSignUp.vue';
import MemberAccount from '@/views/member/MemberAccount.vue';
import MemberUpdatePassword from '@/views/member/MemberUpdatePassword.vue';
import MemberTwoFactorAuth from '@/views/member/MemberTwoFactorAuth.vue';

// Agency
import Agencies from '@/views/agency/Agencies.vue';
import Agency from '@/views/agency/Agency.vue';
import AgencyPostalCodes from '@/views/agency/AgencyPostalCodes.vue';
import AgencyMedias from '@/views/agency/AgencyMedias.vue';
import AgencyMembers from '@/views/agency/AgencyMembers.vue';

// Agenda
import Agenda from '@/views/agenda/Agenda.vue';

// Customer
import Customers from '@/views/customer/Customers.vue';
import Customer from '@/views/customer/Customer.vue';
import CustomerProjects from '@/views/customer/CustomerProjects.vue';
import CustomerContact from '@/views/customer/CustomerContact.vue';
import CustomerAgenda from '@/views/customer/CustomerAgenda.vue';
import CustomerProjectDrafts from '@/views/customer/CustomerProjectDrafts.vue';
import CustomersUnqualified from '@/views/customer/CustomersUnqualified.vue';
import CustomersProspects from '@/views/customer/CustomersProspects.vue';

// Company
import Companies from '@/views/company/Companies.vue';
import CompanySchedule from '@/views/company/CompanySchedule.vue';
import Company from '@/views/company/Company.vue';
import CompanyProjects from '@/views/company/CompanyProjects.vue';
import CompanyPdf from '@/views/pdf/CompanyPdf.vue';
import CompanyAllotments from '@/views/company/CompanyAllotments.vue';
import CompanyLands from '@/views/company/CompanyLands.vue';

// company category
import CompanyCategories from '@/views/companyCategory/CompanyCategories.vue';

// allotment
import Allotments from '@/views/allotment/Allotments.vue';
import Allotment from '@/views/allotment/Allotment.vue';
import AllotmentLands from '@/views/allotment/AllotmentLands.vue';

// land
import Lands from '@/views/land/Lands.vue';
import Land from '@/views/land/Land.vue';
import LandPrices from '@/views/land/LandPrices.vue';

// project draft
import ProjectDraft from '@/views/projectDraft/ProjectDraft.vue';
import ProjectsDrafts from '@/views/projectDraft/ProjectsDrafts.vue';
import ProjectDraftMatchings from '@/views/projectDraft/ProjectDraftMatchings.vue';
import ProjectDraftConfiguration from '@/views/projectDraft/ProjectDraftConfiguration.vue';

// Project
import Projects from '@/views/project/Projects.vue';
import Project from '@/views/project/Project.vue';
import ProjectProjectStep from '@/views/project/ProjectProjectStep.vue';
import ProjectProjectStepGantt from '@/views/project/ProjectProjectStepGantt.vue';
import ProjectPdf from '@/views/pdf/ProjectPdf.vue';
import ProjectsPending from '@/views/project/ProjectsPending.vue';
import ProjectsTodo from '@/views/project/ProjectsTodo.vue';
import ProjectsInProgress from '@/views/project/ProjectsInProgress.vue';
import ProjectsDone from '@/views/project/ProjectsDone.vue';
import ProjectsSummary from '@/views/project/ProjectsSummary.vue';
import ProjectsSummaryPdf from '@/views/pdf/ProjectsSummaryPdf.vue';
import ProjectNotes from '@/views/project/ProjectNotes.vue';
import ProjectsCompaniesReports from '@/views/project/ProjectsCompaniesReports.vue';

// Project step
import ProjectStepsInProgress from '@/views/projectStep/ProjectStepsInProgress.vue';
import ProjectStepsWeek from '@/views/projectStep/ProjectStepsWeek.vue';

// build Filter
import BuildFilters from '@/views/buildFilter/BuildFilters.vue';
import BuildSteps from '@/views/buildFilter/BuildSteps.vue';

// range
import Ranges from '@/views/range/Ranges.vue';

// model
import Models from '@/views/model/Models.vue';

// version
import Version from '@/views/version/Version.vue';
import VersionConstraints from '@/views/version/VersionConstraints.vue';
import VersionExtensions from '@/views/version/VersionExtensions.vue';
import VersionLotsSurveys from '@/views/version/VersionLotsSurveys.vue';
import VersionPrices from '@/views/version/VersionPrices.vue';
import Versions from '@/views/version/Versions.vue';
import VersionSummary from '@/views/version/VersionSummary.vue';
import VersionSurfaces from '@/views/version/VersionSurfaces.vue';
import VersionVariables from '@/views/version/VersionVariables.vue';
import VersionMedias from '@/views/version/VersionMedias.vue';

// category
import Categories from '@/views/category/Categories.vue';

// lot
import Lots from '@/views/lot/Lots.vue';

// theme
import Themes from '@/views/theme/Themes.vue';

// option
import Option from '@/views/option/Option.vue';
import Options from '@/views/option/Options.vue';

// pack
import Pack from '@/views/pack/Pack.vue';
import Packs from '@/views/pack/Packs.vue';

// regulation
import Regulation from '@/views/regulation/Regulation.vue';
import Regulations from '@/views/regulation/Regulations.vue';

// style
import Style from '@/views/style/Style.vue';
import Styles from '@/views/style/Styles.vue';

// constraints
import Constraint from '@/views/constraint/Constraint.vue';
import Constraints from '@/views/constraint/Constraints.vue';

// room
import Rooms from '@/views/room/Rooms.vue';

// foor
import Floors from '@/views/floor/Floors.vue';

// Coefficient
import Coefficients from '@/views/coefficient/Coefficients.vue';

// lot-survey
import LotsSurveys from '@/views/lotSurvey/LotsSurveys.vue';

// variable
import Variables from '@/views/variable/Variables.vue';

// push
import Pushes from '@/views/push/Pushes.vue';

// brand
import Brand from '@/views/brand/Brand.vue';
import BrandMembers from '@/views/brand/BrandMembers.vue';
import BrandParameters from '@/views/brand/BrandParameters.vue';

// diffusion
import DiffusionAdvertisementPacks from '@/views/diffusion/DiffusionAdvertisementPacks.vue';
import DiffusionLogs from '@/views/diffusion/DiffusionLogs.vue';
import DiffusionQuota from '@/views/diffusion/DiffusionQuota.vue';
import DiffusionStatistics from '@/views/diffusion/DiffusionStatistics.vue';
import DiffusionTemplates from '@/views/diffusion/DiffusionTemplates.vue';
import DiffusionUbiflow from '@/views/diffusion/DiffusionUbiflow.vue';

// advertisement pack
import AdvertisementPack from '@/views/advertisementPack/AdvertisementPack.vue';
import AdvertisementPacks from '@/views/advertisementPack/AdvertisementPacks.vue';

// advertisement
import Advertisement from '@/views/advertisement/Advertisement.vue';
import Advertisements from '@/views/advertisement/Advertisements.vue';

// pdfs
import ConfigurationPdf from '@/views/pdf/ConfigurationPdf.vue';

// documents
import DocumentsSpace from '@/views/document/DocumentsSpace.vue';
import Documents from '@/views/document/Documents.vue';
import DocumentsSubCategories from '@/views/document/DocumentsSubCategories.vue';

// opportunities
import Opportunities from '@/views/opportunities/Opportunities.vue';

// indicators
import Indicators from '@/views/indicators/Indicators.vue';

// Listen back navigation browser
let isPreviousChange = false;
window.addEventListener('popstate', () => {
  isPreviousChange = true;
});

// Routes
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: {
        name: 'home',
      },
      meta: { hideTopbar: true, hideSideNav: true },
    },
    {
      path: '/connexion',
      name: 'login',
      component: MemberLogin,
      meta: { hideTopbar: true, hideSideNav: true },
    },
    {
      path: '/connexion/double-authentification',
      name: 'two-factor-authentication',
      component: MemberTwoFactorAuth,
      meta: { hideTopbar: true, hideSideNav: true },
    },
    {
      path: '/avant-projets/:projectDraftId/pdf',
      name: 'project-drafts-projectDraftId-configuration-pdf',
      component: ConfigurationPdf,
      meta: { hideTopbar: true, hideSideNav: true, print: true },
    },
    {
      path: '/mot-de-passe-perdu',
      name: 'lost-password',
      component: MemberLostPassword,
      meta: { hideTopbar: true, hideSideNav: true },
    },
    {
      path: '/mot-de-passe-perdu/modification',
      name: 'reset-password',
      component: MemberResetPassword,
      meta: { hideTopbar: true, hideSideNav: true },
    },
    {
      path: '/compte',
      name: 'account',
      component: MemberAccount,
    },
    {
      path: '/compte/mot-de-passe',
      name: 'account-password',
      component: MemberUpdatePassword,
    },
    {
      path: '/invitation',
      name: 'sign-up',
      component: MemberSignUp,
      meta: { hideTopbar: true, hideSideNav: true },
    },
    {
      path: '/accueil',
      name: 'home',
      component: Home,
      meta: { hideSideNav: true },
    },
    {
      path: '/design-system',
      name: 'design-system',
      component: DesignSystem,
    },
    {
      path: '/pdf/chantiers/:projectId',
      name: 'pdf-project',
      component: ProjectPdf,
      meta: { hideTopbar: true, hideSideNav: true, print: true },
    },
    {
      path: '/pdf/partenaires/:companyId',
      name: 'pdf-company',
      component: CompanyPdf,
      meta: { hideTopbar: true, hideSideNav: true, print: true },
    },

    // HEDIFYS 21 ------------------

    // PARTIE AGENCE
    {
      path: '/agences',
      name: 'agencies',
      component: Agencies,
    },
    {
      path: '/agences/:agencyId',
      name: 'agencies-agencyId',
      component: Agency,
    },
    {
      path: '/agences/:agencyId/codes-postaux',
      name: 'agencies-agencyId-postal-codes',
      component: AgencyPostalCodes,
    },
    {
      path: '/agences/:agencyId/medias',
      name: 'agencies-agencyId-medias',
      component: AgencyMedias,
    },
    {
      path: '/agences/:agencyId/membres',
      name: 'agencies-agencyId-members',
      component: AgencyMembers,
    },
    // PARTIE CONTACTS
    // unqualified
    {
      path: '/non-qualifies',
      name: 'customers-unqualified',
      component: CustomersUnqualified,
    },
    {
      path: '/non-qualifies/nouveau',
      name: 'customers-unqualified-new',
      component: Customer,
    },
    {
      path: '/non-qualifies/:customerId',
      name: 'customers-unqualified-customerId',
      component: Customer,
    },
    {
      path: '/non-qualifies/:customerId/avant-projets',
      name: 'customers-unqualified-customerId-draft-projects',
      component: CustomerProjectDrafts,
    },
    {
      path: '/non-qualifies/:customerId/prise-de-contact',
      name: 'customers-unqualified-customerId-contact',
      component: CustomerContact,
    },
    {
      path: '/non-qualifies/:customerId/agenda',
      name: 'customers-unqualified-customerId-agenda',
      component: CustomerAgenda,
    },

    // prospect
    {
      path: '/prospects',
      name: 'customers-prospects',
      component: CustomersProspects,
    },
    {
      path: '/prospects/nouveau',
      name: 'customers-prospects-new',
      component: Customer,
    },
    {
      path: '/prospects/:customerId',
      name: 'customers-prospects-customerId',
      component: Customer,
    },
    {
      path: '/prospects/:customerId/avant-projets',
      name: 'customers-prospects-customerId-draft-projects',
      component: CustomerProjectDrafts,
    },
    {
      path: '/prospects/:customerId/prise-de-contact',
      name: 'customers-prospects-customerId-contact',
      component: CustomerContact,
    },
    {
      path: '/prospects/:customerId/agenda',
      name: 'customers-prospects-customerId-agenda',
      component: CustomerAgenda,
    },

    {
      path: '/avant-projets',
      name: 'customers-projects-drafts',
      component: ProjectsDrafts,
    },
    {
      path: '/avant-projets/nouveau',
      name: 'customers-projects-drafts-new',
      component: ProjectDraftMatchings,
    },
    {
      path: '/avant-projets/modification',
      name: 'customers-projects-drafts-edit',
      component: ProjectDraftMatchings,
    },
    {
      path: '/avant-projets/:projectDraftId',
      name: 'customers-projects-drafts-projectDraftId',
      component: ProjectDraft,
    },
    {
      path: '/avant-projets/:projectDraftId/modification',
      name: 'customers-projects-drafts-projectDraftId-edit',
      component: ProjectDraftMatchings,
    },
    {
      path: '/avant-projets/nouveau/configuration/:tab',
      name: 'customers-projects-drafts-new-configuration',
      component: ProjectDraftConfiguration,
      beforeEnter: (to, from, next) => {
        const tabs = ['version', 'surfaces', 'categories', 'options', 'pack', 'land', 'fees', 'extensions'];

        // Check des tabs possibles pour cette route
        if (tabs.includes(to.params.tab)) {
          next();
        } else {
          next({
            name: 'customers-projects-drafts-new-configuration',
            params: {
              ...to.params,
              tab: tabs[0],
            },
            query: {
              ...to.query,
            },
          });
        }
      },
    },
    {
      path: '/avant-projets/:projectDraftId/configuration/:tab',
      name: 'customers-projects-drafts-projectDraftId-configuration',
      component: ProjectDraftConfiguration,
      beforeEnter: (to, from, next) => {
        const tabs = ['version', 'surfaces', 'categories', 'options', 'pack', 'land', 'fees', 'extensions'];
        // Check des tabs possibles pour cette route
        if (tabs.includes(to.params.tab)) {
          next();
        } else {
          next({
            name: 'customers-projects-drafts-projectDraftId-configuration',
            params: {
              projectDraftId: to.params.projectDraftId,
              tab: tabs[0],
            },
            query: {
              ...to.query,
            },
          });
        }
      },
    },
    // PARTIE PARTENAIRE
    {
      path: '/partenaires',
      name: 'companies',
      component: Companies,
    },
    {
      path: '/partenaires/nouveau',
      name: 'companies-new',
      component: Company,
    },
    {
      path: '/partenaires/:companyId',
      name: 'companies-companyId',
      component: Company,
    },
    {
      path: '/partenaires/:companyId/projets',
      name: 'companies-companyId-projects',
      component: CompanyProjects,
    },
    {
      path: '/partenaires/:companyId/disponibilite',
      name: 'companies-companyId-schedule',
      component: CompanySchedule,
    },
    {
      path: '/partenaires/:companyId/lotissement',
      name: 'companies-companyId-allotments',
      component: CompanyAllotments,
    },
    {
      path: '/partenaires/:companyId/terrains',
      name: 'companies-companyId-lands',
      component: CompanyLands,
    },
    // PARTIE METIER
    {
      path: '/metiers',
      name: 'companies-categories',
      component: CompanyCategories,
    },
    // PARTIE TERRAIN
    {
      path: '/terrains',
      name: 'lands',
      component: Lands,
    },
    {
      path: '/terrains/nouveau',
      name: 'lands-new',
      component: Land,
    },
    {
      path: '/terrains/:landId',
      name: 'lands-landId',
      component: Land,
    },
    {
      path: '/lotissements',
      name: 'lands-allotments',
      component: Allotments,
    },
    {
      path: '/lotissements/nouveau',
      name: 'lands-allotments-new',
      component: Allotment,
    },
    {
      path: '/lotissements/:allotmentId',
      name: 'lands-allotments-allotmentId',
      component: Allotment,
    },
    {
      path: '/lotissements/:allotmentId/terrains',
      name: 'lands-allotments-allotmentId-lands',
      component: AllotmentLands,
    },
    {
      path: '/prix-moyen-terrains-par-ville',
      name: 'land-prices',
      component: LandPrices,
    },
    // PARTIE CHANTIER / Projects

    // clients
    {
      path: '/clients',
      name: 'projects-customers',
      component: Customers,
    },
    {
      path: '/clients/nouveau',
      name: 'projects-customers-new',
      component: Customer,
    },
    {
      path: '/clients/:customerId',
      name: 'projects-customers-customerId',
      component: Customer,
    },
    {
      path: '/clients/:customerId/chantiers',
      name: 'projects-customers-customerId-projects',
      component: CustomerProjects,
    },
    {
      path: '/clients/:customerId/avant-projets',
      name: 'projects-customers-customerId-draft-projects',
      component: CustomerProjectDrafts,
    },
    {
      path: '/clients/:customerId/agenda',
      name: 'projects-customers-customerId-agenda',
      component: CustomerAgenda,
    },
    {
      path: '/chantiers-synthese',
      name: 'projects-summary',
      component: ProjectsSummary,
    },
    {
      path: '/chantiers-synthese/pdf',
      name: 'projects-summary-pdf',
      component: ProjectsSummaryPdf,
      meta: { hideTopbar: true, hideSideNav: true, print: true },
    },
    {
      path: '/chantiers',
      name: 'projects',
      component: Projects,
    },
    {
      path: '/chantiers/en-attente',
      name: 'projects-pending',
      component: ProjectsPending,
    },
    {
      path: '/chantiers/en-prevision',
      name: 'projects-todo',
      component: ProjectsTodo,
    },
    {
      path: '/chantiers/en-cours',
      name: 'projects-in-progress',
      component: ProjectsInProgress,
    },
    {
      path: '/chantiers/finis',
      name: 'projects-done',
      component: ProjectsDone,
    },
    {
      path: '/chantiers/:projectId/taches',
      name: 'projects-projectId-steps',
      component: ProjectProjectStep,
    },
    {
      path: '/chantiers/:projectId/gantt',
      name: 'projects-projectId-steps-gantt',
      component: ProjectProjectStepGantt,
    },
    {
      path: '/chantiers/nouveau',
      name: 'projects-new',
      component: Project,
    },
    {
      path: '/chantiers/:projectId',
      name: 'projects-projectId',
      component: Project,
    },
    {
      path: '/chantiers/:projectId/notes',
      name: 'projects-projectId-notes',
      component: ProjectNotes,
    },
    {
      path: '/gabarits',
      name: 'projects-build-filters',
      component: BuildFilters,
    },
    {
      path: '/gabarits/:buildFilterId',
      name: 'projects-build-filters-buildFilterId',
      component: BuildSteps,
    },
    {
      path: '/rapports-partenaires',
      name: 'projects-companies-reports',
      component: ProjectsCompaniesReports,
    },
    // PARTIE TACHE
    {
      path: '/taches-en-cours',
      name: 'projects-project-steps',
      component: ProjectStepsInProgress,
    },
    {
      path: '/taches-semaine',
      name: 'projects-project-steps-week',
      component: ProjectStepsWeek,
    },
    // PARTIE CATALOGUE
    {
      path: '/versions',
      name: 'versions',
      component: Versions,
    },
    {
      path: '/versions/nouveau',
      name: 'versions-new',
      component: Version,
    },
    {
      path: '/versions/:versionId',
      name: 'versions-versionId',
      component: Version,
    },
    {
      path: '/versions/:versionId/extensions',
      name: 'versions-versionId-extensions',
      component: VersionExtensions,
    },
    {
      path: '/versions/:versionId/constraints',
      name: 'versions-versionId-constraints',
      component: VersionConstraints,
    },
    {
      path: '/versions/:versionId/lots-metres',
      name: 'versions-versionId-lots-surveys',
      component: VersionLotsSurveys,
    },
    {
      path: '/versions/:versionId/prices',
      name: 'versions-versionId-prices',
      component: VersionPrices,
    },
    {
      path: '/versions/:versionId/summary',
      name: 'versions-versionId-summary',
      component: VersionSummary,
    },
    {
      path: '/versions/:versionId/surfaces',
      name: 'versions-versionId-surfaces',
      component: VersionSurfaces,
    },
    {
      path: '/versions/:versionId/variables',
      name: 'versions-versionId-variables',
      component: VersionVariables,
    },
    {
      path: '/versions/:versionId/medias',
      name: 'versions-versionId-medias',
      component: VersionMedias,
    },
    {
      path: '/models',
      name: 'versions-models',
      component: Models,
    },
    {
      path: '/gammes',
      name: 'versions-ranges',
      component: Ranges,
    },
    // PARTIE STRUCTURE
    {
      path: '/categories',
      name: 'categories',
      component: Categories,
    },
    {
      path: '/lots',
      name: 'categories-lots',
      component: Lots,
    },
    {
      path: '/themes',
      name: 'categories-themes',
      component: Themes,
    },
    {
      path: '/options',
      name: 'categories-options',
      component: Options,
    },
    {
      path: '/options/nouveau',
      name: 'categories-options-new',
      component: Option,
    },
    {
      path: '/options/:optionId',
      name: 'categories-options-optionId',
      component: Option,
    },
    {
      path: '/packs',
      name: 'categories-packs',
      component: Packs,
    },
    {
      path: '/packs/nouveau',
      name: 'categories-packs-new',
      component: Pack,
    },
    {
      path: '/packs/:packId',
      name: 'categories-packs-packId',
      component: Pack,
    },
    {
      path: '/rooms',
      name: 'categories-rooms',
      component: Rooms,
    },
    {
      path: '/floors',
      name: 'categories-floors',
      component: Floors,
    },
    // PARTIE SPÉCIFICITÉ
    {
      path: '/styles',
      name: 'styles',
      component: Styles,
    },
    {
      path: '/styles/nouveau',
      name: 'styles-new',
      component: Style,
    },
    {
      path: '/styles/:styleId',
      name: 'styles-styleId',
      component: Style,
    },
    {
      path: '/regles',
      name: 'styles-regulations',
      component: Regulations,
    },
    {
      path: '/regles/nouveau',
      name: 'styles-regulations-new',
      component: Regulation,
    },
    {
      path: '/regles/:regulationId',
      name: 'styles-regulations-regulationId',
      component: Regulation,
    },
    {
      path: '/contraintes',
      name: 'styles-constraints',
      component: Constraints,
    },
    {
      path: '/contraintes/nouveau',
      name: 'styles-constraints-new',
      component: Constraint,
    },
    {
      path: '/contraintes/:constraintId',
      name: 'styles-constraints-constraintId',
      component: Constraint,
    },
    // PARTIE FINANCIER
    {
      path: '/marges',
      name: 'coefficients',
      component: Coefficients,
    },
    {
      path: '/variables',
      name: 'coefficients-variables',
      component: Variables,
    },
    {
      path: '/lot-metres',
      name: 'coefficients-lots-surveys',
      component: LotsSurveys,
    },
    {
      path: '/push-prix',
      name: 'coefficients-pushes',
      component: Pushes,
    },
    // PARTIE MARQUE
    {
      path: '/marque',
      name: 'brand',
      component: Brand,
    },
    {
      path: '/marque/membres',
      name: 'brand-members',
      component: BrandMembers,
    },
    {
      path: '/marque/parametres',
      name: 'brand-parameters',
      component: BrandParameters,
    },
    // PARTIE DIFFUSION
    {
      path: '/configuration-diffusion',
      name: 'diffusions-quotas',
      component: DiffusionQuota,
    },
    {
      path: '/configuration-diffusion/statistiques-diffusion',
      name: 'diffusions-statistics',
      component: DiffusionStatistics,
    },
    {
      path: '/configuration-diffusion/templates-diffusion',
      name: 'diffusions-templates',
      component: DiffusionTemplates,
    },
    {
      path: '/configuration-diffusion/ubiflow-diffusion',
      name: 'diffusions-ubiflow',
      component: DiffusionUbiflow,
    },
    {
      path: '/configuration-diffusion/packs-diffusion',
      name: 'diffusions-advertisement-packs',
      component: DiffusionAdvertisementPacks,
    },
    {
      path: '/configuration-diffusion/logs-diffusion',
      name: 'diffusions-logs',
      component: DiffusionLogs,
    },
    // PARTIE PACK DIFFUSION
    {
      path: '/pack-annonces',
      name: 'diffusions-advertisement-diffusion-packs',
      component: AdvertisementPacks,
    },
    {
      path: '/pack-annonces/nouveau',
      name: 'diffusions-advertisements-diffusion-packs-new',
      component: AdvertisementPack,
    },
    {
      path: '/pack-annonces/:advertisementPackId',
      name: 'diffusions-advertisement-diffusion-packs-advertisementPackId',
      component: AdvertisementPack,
    },
    // PARTIE ANNONCES
    {
      path: '/annonces',
      name: 'diffusions-advertisements',
      component: Advertisements,
    },
    {
      path: '/annonces/new',
      name: 'diffusions-advertisements-new',
      component: Advertisement,
    },
    {
      path: '/annonces/:advertisementId',
      name: 'diffusions-advertisements-advertisementId',
      component: Advertisement,
    },
    // PARTIE AGENDA
    {
      path: '/agenda',
      name: 'agenda',
      component: Agenda,
    },
    // PARTIE DOCUMENT
    {
      path: '/espace-docs',
      name: 'documents-space',
      component: DocumentsSpace,
    },
    {
      path: '/espace-docs/:documentCategoryId/documents',
      name: 'documents-space-documents',
      component: Documents,
    },
    {
      path: '/espace-docs/:documentCategoryId/sous-rubriques',
      name: 'documents-space-subcategories',
      component: DocumentsSubCategories,
    },
    // PARTIE OPPORTUNITES
    {
      path: '/opportunities',
      name: 'opportunities',
      component: Opportunities,
    },
    // PARTIE INDICATEURS
    {
      path: '/indicateurs',
      name: 'indicators',
      component: Indicators,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// Handling hedifys custom previous page
ls.set('PREVIOUS_ROUTES', []);

router.afterEach((to, from) => {
  let previousRoutes = ls('PREVIOUS_ROUTES');

  // Analytics
  if (typeof gtag !== 'undefined') {
    // eslint-disable-next-line
    gtag('event', 'page_view', {
      page_path: to.fullPath,
      page_title: document.title,
      page_location: window.location.href,
    });
  }

  // Changement sans retour arrière (navigateur ou custom Hedifys)
  if (!isPreviousChange) {
    // pages avec retour et scope différent
    if ((from.meta.scope !== to.meta.scope) && !!from.meta.detailbar && !!to.meta.detailbar) previousRoutes.push({ name: from.name, params: from.params });
    // page de départ existante et sans retour, page d'arrivée avec retour
    else if (from.name && !from.meta.detailbar && !!to.meta.detailbar) previousRoutes.push({ name: from.name, params: from.params });
    // page d'arrivée avec retour et page de départ existantes
    else if (!to.meta.detailbar || !from.name) previousRoutes = [];
  }

  // Changement avec retour arrière et route d'arrivée et celle stockée identique => suppression de la route stockée
  if (isPreviousChange && previousRoutes.length > 0 && (to.name === previousRoutes[previousRoutes.length - 1].name)) previousRoutes.pop();

  isPreviousChange = false;
  ls.set('PREVIOUS_ROUTES', previousRoutes);
});

router.previousRoute = (currentRoute) => {
  const previousRoutes = ls('PREVIOUS_ROUTES');
  const prevRoute = previousRoutes.pop();

  isPreviousChange = true;

  if (prevRoute) router.push(prevRoute);
  // Fallback borderline si on tombe sur une page avec retour sans historique
  else if (currentRoute.meta.scope) router.push({ name: `${currentRoute.meta.scope}-list` });
};

Vue.use(Router);

export default router;
