<template>
  <div class="calendar hedifys-21">
    <!-- HEADER / TITRE -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>Agenda</h1>
      </div>
      <div class="cell shrink">
        <app-button theme="primary" size="large" icon="add" @click="addCustomerEvent">Ajouter un évenement</app-button>
      </div>
    </div>
    <!-- BODY / CALENDRIER -->
    <div class="body">
      <!-- filters -->
      <div class="filters-container">
        <app-select v-if="isBrandAdmin" v-model="selectedFilterAgencyId" :options="[{ label: 'Toutes les agences', name: null }].concat(agencyOptions)" />
        <app-select v-model="selectedFilterCategory" :options="[{ label: 'Toutes les catégories', name: null }].concat(categoryOptions)" class="category-select" />
        <app-button
          theme="primary"
          :icon="copyToClipboardSucessful ? 'checked' : 'copy'"
          size="small"
          @click="copyUrlCalendar"
          :disabled="!selectedFilterAgencyId || copyToClipboardSucessful"
        >{{ copyToClipboardSucessful ? 'Copié !' : 'Copier l\'URL du calendrier' }}</app-button>
      </div>
      <app-calendar minTime="07:00:00" maxTime="22:00:00" :events="fullcalendarEvents" @eventClick="openEvent" @dateClick="handleDateClick" />
    </div>

    <!-- MODALE - CRÉATION -->
    <app-modal-draggable
      :title="customerEvent.customerEventId ? (customerEvent.canceledAt ? 'Événement annulé' : 'Détails de l\'évènement') : 'Nouvel évènement'"
      @show="showCustomerEventModal = true "
      @hide="showCustomerEventModal = false "
    >
      <form @submit.prevent="customerEvent.customerEventId ? handleClickOnUpdateCustomerEvent() : createCustomerEvent()">
        <div class="contact">
          <div class="form-row">
            <app-input
              v-if="!customerEvent.customer.customerId && !freeEventTypes.includes(customerEvent.category)"
              :value="customerEvent.customer.customerId ? `${customerEvent.customer.firstname} ${customerEvent.customer.lastname}` : ''"
              placeholder="Choisir un contact..."
              label="Choix du contact"
              @focus="openSelectCustomerModal"
              required
            />
            <div v-else-if="customerEvent.customer.customerId && !freeEventTypes.includes(customerEvent.category)" class="selected-customer">
              <div class="selected-customer-header">
                <p>Choix du contact *</p>
                <a @click="openSelectCustomerModal" v-if="!customerEvent.canceledAt">Modifier</a>
              </div>
              <div class="selected-customer-input">
                <router-link :to="{ name: 'customers-prospects-customerId', params: { customerId: customerEvent.customer.customerId } }" target="_blank">
                  <h6>{{ customerEvent.customer.firstname }} {{ customerEvent.customer.lastname }}</h6>
                </router-link>
                <p :class="`value-${customerEvent.customer.status}`">{{ getCustomerStatusLabel }}</p>
              </div>
            </div>
            <app-select
              class="category-select"
              :options="!isBrandAdmin ? (customerEvent.customerEventId && customerEvent.recurrencePeriod !== 'NO_REPEAT' ? categoryOptions.filter((c) => freeEventTypes.includes(c.name)) : categoryOptions) : categoryOptions.filter((c) => !freeEventTypes.includes(c.name))"
              label="Catégorie du rendez-vous"
              v-model="customerEvent.category"
              required
              :disabled="!!customerEvent.canceledAt"
            />
          </div>
          <div class="new-contact" v-if="!customerEvent.customerId && !freeEventTypes.includes(customerEvent.category)">
            <router-link :to="{ name: 'customers-unqualified-new' }" target="_blank">+ Nouveau contact</router-link>
          </div>
        </div>
        <app-input
          label="Nom de l'évènement"
          placeholder="Nom..."
          v-model="customerEvent.name"
          required
          :disabled="!!customerEvent.canceledAt"
        />
        <div class="form-row" v-if="!freeEventTypes.includes(customerEvent.category)">
          <app-input
            class="email"
            type="email"
            placeholder="prospect@mail.com..."
            label="Email du contact"
            v-model="customerEvent.customer.email"
            :disabled="!!customerEvent.canceledAt"
            size="large"
          />
          <div class="phone">
            <span class="phone-label">Numéros de téléphone à prévenir par SMS</span>
            <div>
              <app-checkbox name="phone"
                :value="customerEvent.customer.phone"
                v-model="phoneNumber"
                :disabled="!!customerEvent.canceledAt">
                Principal : {{ customerEvent.customer.phone }}
              </app-checkbox>
            </div>
            <div>
              <app-checkbox name="phoneSecond"
                :value="customerEvent.customer.secondaryContactPhone"
                v-model="phoneNumberSecondary"
                :disabled="!customerEvent.customer.secondaryContactPhone || !!customerEvent.canceledAt">
                Secondaire : {{ customerEvent.customer.secondaryContactPhone }}
              </app-checkbox>
            </div>
            <app-input name="phoneThird"
              type="text"
              placeholder="Autre numéro"
              v-model="phoneNumberThird"
              :disabled="!!customerEvent.canceledAt"
              size="large"
            />
          </div>
        </div>
        <div v-if="!(customerEvent.customerEventId && customerEvent.recurrenceId)" class="form-row">
          <div class="date-container">
            <app-datepicker
              label="Date de début"
              v-model="customerEvent.startedAt"
              required
              :disabled="!!customerEvent.canceledAt"
            />
            <app-timepicker
              :value="$dayjs(customerEvent.startedAt).format('HH:mm')"
              @input="customerEvent.startedAt = setTime(customerEvent.startedAt, $event)"
              :disabled="!!customerEvent.canceledAt"
              min="07:00"
              max="22:00"
            />
          </div>
          <div class="date-container">
            <app-datepicker
              label="Date de fin"
              v-model="customerEvent.endedAt"
              :disabledDates="{ to: customerEvent.startedAt }"
              :disabled="!!customerEvent.canceledAt"
            />
            <app-timepicker
              :value="$dayjs(customerEvent.endedAt).format('HH:mm')"
              @input="customerEvent.endedAt = setTime(customerEvent.endedAt, $event)"
              :min="minEndedAtTime"
              max="22:00"
              :disabled="!!customerEvent.canceledAt"
            />
          </div>
        </div>
        <app-select
              v-if="freeEventTypes.includes(customerEvent.category) && !customerEvent.customerEventId"
              class="period-select"
              :options="customerEventRecurrencePeriods"
              label="Récurrence"
              v-model="customerEvent.recurrencePeriod"
              required
              :disabled="!!customerEvent.canceledAt"
            />
        <app-textarea type="textarea" label="Description" v-model="customerEvent.description" placeholder="Description de l'évènement..." :disabled="!!customerEvent.canceledAt" />
        <div class="cta-containers" v-if="!customerEvent.canceledAt">
          <template v-if="customerEvent.customerEventId">
            <app-button type="submit" icon="save">Enregistrer les modifications</app-button>
            <app-button v-if="freeEventTypes.includes(currentCustomerEventCategory)" theme="secondary" @click="handleClickOnDeleteCustomerEvent">Supprimer l'événement</app-button>
            <app-button v-else theme="secondary" @click="cancelCustomerEvent">Annuler l'événement</app-button>
          </template>
          <app-button v-else type="submit" icon="add" :disabled="isLoading">Créer un événement</app-button>
        </div>
      </form>
    </app-modal-draggable>

    <!-- MODALE - SÉLECTION CLIENT -->
    <select-customer-modal :show="showSelectCustomerModal" v-model="customerEvent.customer" @input="closeSelectCustomerModal" @update:show="closeSelectCustomerModal"/>
    <!-- MODALE - SUPPRESSION RECURRENCE -->
    <app-modal class="recurrence-modal" :show="showRecurrenceDeleteModal" size="small" title="Supprimer cet évènement" @update:show="showRecurrenceDeleteModal = false">
      <div>
                <div>
                  <app-radio
                    name="isDeleteAllRecurrence"
                    :value="false"
                    v-model="isDeleteAllRecurrence"
                  >
                    Seulement cet évènement
                  </app-radio>
                </div>
                <div>
                  <app-radio
                    name="isDeleteAllRecurrence"
                    :value="true"
                    v-model="isDeleteAllRecurrence"
                  >
                    Tous les évènements
                  </app-radio>
                </div>
              </div>
        <div class="buttons-container">
          <app-button size="large" theme="error" @click="deleteCustomerEventRecurrence">Supprimer</app-button>
          <app-button @click="showRecurrenceDeleteModal = false" size="large" theme="secondary">Annuler</app-button>
        </div>
      </app-modal>
      <!-- MODALE - MODIFICATION RECURRENCE -->
      <app-modal class="recurrence-modal" :show="showRecurrenceUpdateModal" size="small" title="Modifier cet évènement" @update:show="showRecurrenceUpdateModal = false">
      <div>
                <div>
                  <app-radio
                    name="isUpdateAllRecurrence"
                    :value="false"
                    v-model="isUpdateAllRecurrence"
                  >
                    Seulement cet évènement
                  </app-radio>
                </div>
                <div>
                  <app-radio
                    name="isUpdateAllRecurrence"
                    :value="true"
                    v-model="isUpdateAllRecurrence"
                  >
                    Tous les évènements
                  </app-radio>
                </div>
              </div>
        <div class="buttons-container">
          <app-button size="large" theme="error" @click="updateAllRecurrencesCustomerEvent">Modifier</app-button>
          <app-button @click="showRecurrenceUpdateModal = false" size="large" theme="secondary">Annuler</app-button>
        </div>
      </app-modal>
  </div>
</template>

<script>
import customerEventCategories from '@/services/data/customerEventCategories.json';
import customerEventRecurrencePeriods from '@/services/data/customerEventRecurrencePeriods.json';
import customerStatuses from '@/services/data/customerStatuses.json';
import customerEventApi from '@/services/api/customerEvent';
import agencyApi from '@/services/api/agency';
import memberApi from '@/services/api/member';
import SelectCustomerModal from '@/components/general/SelectCustomerModal.vue';

export default {
  name: 'calendar',
  components: {
    SelectCustomerModal,
  },
  data() {
    return {
      customerEvents: [],
      // Filtre agence
      selectedFilterAgencyId: null,
      agencyOptions: [],
      // Filtre catégorie
      selectedFilterCategory: null,
      categoryOptions: customerEventCategories,
      // Création/Edition d'un evt
      showCustomerEventModal: false,
      showSelectCustomerModal: false,
      copyToClipboardSucessful: false,
      customerEvent: {
        name: null,
        startedAt: null,
        endedAt: null,
        customer: {
          customerId: null,
          firstname: null,
          lastname: null,
          email: null,
          phone: null,
          secondaryContactPhone: null,
        },
        category: null,
        description: null,
        recurrencePeriod: 'NO_REPEAT',
        phone: null,
        secondaryPhone: null,
      },
      currentCustomerEventCategory: null,
      isBrandAdmin: null,
      me: null,
      customerStatuses,
      phoneNumber: [],
      phoneNumberSecondary: [],
      phoneNumberThird: null,
      calendarUrl: null,
      isLoading: false,
      freeEventTypes: customerEventCategories.filter((cat) => cat.name.startsWith('FREE')).map((cat) => cat.name),
      customerEventRecurrencePeriods,
      showRecurrenceDeleteModal: false,
      isDeleteAllRecurrence: false,
      showRecurrenceUpdateModal: false,
      isUpdateAllRecurrence: false,
    };
  },
  computed: {
    // Liste des événements forattés pour fullcalendar
    fullcalendarEvents() {
      return this.customerEvents.map((evt) => ({
        id: evt.customerEventId,
        title: evt.name,
        start: evt.startedAt,
        end: evt.endedAt,
        description: evt.description,
        classNames: evt.canceledAt ? ['canceled'] : [evt.category.toLowerCase().replace('_', '-')],
      }));
    },
    // Horaire minimum à respecter pour la fin du rdv (selon date et heure de début)
    minEndedAtTime() {
      const { startedAt, endedAt } = this.customerEvent;
      if (startedAt && endedAt && this.$dayjs(startedAt).isSame(endedAt, 'day')) {
        return this.$dayjs(startedAt).format('HH:mm');
      }
      return '07:00';
    },
    getCustomerStatusLabel() {
      const { customer } = this.customerEvent;
      if (customer && customer.status) {
        const status = this.customerStatuses.find((s) => s.name === customer.status);
        return status ? status.label : '';
      }
      return '';
    },
  },
  mounted() {
    this.getCustomerEvents();
    this.getMe();
  },
  watch: {
    // Trigger recherche d'évément aux changement des filtres
    selectedFilterCategory() {
      this.getCustomerEvents();
    },
    async selectedFilterAgencyId() {
      this.copyToClipboardSucessful = false;
      this.getCustomerEvents();
      const response = await customerEventApi.getCalendarLink(this.selectedFilterAgencyId);
      this.calendarUrl = response && response.data ? response.data : '';
    },
    // Si l'utilisateur est admin, récupérer la liste d'agence
    isBrandAdmin() {
      if (this.isBrandAdmin) {
        this.getAgencies();
      }
    },
    copyToClipboardSucessful(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.copyToClipboardSucessful = false;
        }, 3000);
      }
    },
    'customerEvent.startedAt': {
      handler() {
        if (this.customerEvent) {
          const { startedAt, canceledAt, endedAt } = this.customerEvent;
          if (startedAt && !canceledAt && !endedAt) {
            this.customerEvent.endedAt = this.$dayjs(startedAt).add(1, 'hour').toDate();
          }
        }
      },
    },
    'customerEvent.customer': {
      handler() {
        if (this.customerEvent?.customer?.customerId && this.customerEvent.category) {
          const categoryLabel = customerEventCategories.find((s) => s.name === this.customerEvent.category)?.label;
          this.customerEvent.name = `${categoryLabel || 'RDV'} - ${this.customerEvent.customer.firstname} ${this.customerEvent.customer.lastname}`;
        }

        if (this.customerEvent?.customer?.status) {
          const index = this.customerStatuses.findIndex((s) => s.name === this.customerEvent.customer.status);
          if (index && index + 1 !== this.customerStatuses.length) {
            this.customerEvent.category = this.customerStatuses[index + 1].name;
          } else {
            this.customerEvent.category = this.customerStatuses[2].name;
          }
        }

        if (this.customerEvent?.phone) this.phoneNumber.push(this.customerEvent.phone);
        if (this.customerEvent?.secondaryPhone) this.phoneNumberSecondary.push(this.customerEvent.secondaryPhone);
        if (this.customerEvent?.thirdPhone) this.phoneNumberThird = this.customerEvent.thirdPhone;
      },
    },
    'customerEvent.category': {
      handler() {
        if (!this.customerEvent.customerEventId) {
          this.customerEvent.name = null;
        }
        if (this.freeEventTypes.includes(this.customerEvent.category)) {
          this.customerEvent.customer = {
            customerId: null,
            firstname: null,
            lastname: null,
            phone: null,
            secondaryContactPhone: null,
            email: null,
          };
          if (this.customerEvent.category !== 'FREE' && !this.customerEvent.customerEventId) {
            this.customerEvent.name = customerEventCategories.filter((cat) => cat.name === this.customerEvent.category)[0].label;
          }
          this.phoneNumber = [];
          this.phoneNumberSecondary = [];
          this.phoneNumberThird = null;
        }
        if (this.customerEvent.customer.customerId && this.customerEvent.category) {
          const categoryLabel = customerEventCategories.find((s) => s.name === this.customerEvent.category)?.label;
          this.customerEvent.name = `${categoryLabel || 'RDV'} - ${this.customerEvent.customer.firstname} ${this.customerEvent.customer.lastname}`;
        }
      },
    },
    // Reset le customerEvent à la fermeture de la modale
    showCustomerEventModal(isOpening) {
      if (!isOpening) {
        this.$modal.hide('modal-draggable');
        this.resetCustomerEvent();
        this.getCustomerEvents();
      } else {
        this.$modal.show('modal-draggable');
      }
    },
  },
  methods: {
    addCustomerEvent() {
      this.resetCustomerEvent();
      this.showCustomerEventModal = true;
    },
    // Ouvrir la modale au click sur le calendrier
    handleDateClick({ date }) {
      this.resetCustomerEvent();
      this.customerEvent.startedAt = new Date(new Date(date).setHours(date.getHours()));
      this.showCustomerEventModal = true;
    },
    // Modifie l'horaire d'un timestamp en fonction de la valeur retournée par un timepicker
    setTime(date, time) {
      const [hour, minute] = time.split(':');
      // Utilises la date du jour si date invalide
      const isDateInvalid = !date || Number.isNaN(date.getTime());
      const dateDayjs = this.$dayjs(isDateInvalid ? undefined : date).hour(hour).minute(minute);
      return dateDayjs.toDate();
    },
    // Ouvre la modale d'édition d'un événement-client
    openEvent(event) {
      this.resetCustomerEvent();
      const customerEvent = this.customerEvents.find((customerEvt) => customerEvt.customerEventId === event.event.id);
      this.currentCustomerEventCategory = customerEvent.category;
      this.customerEvent = customerEvent;
      this.showCustomerEventModal = true;
    },
    // Ouvre la modale de sélection client après un délai de transition
    openSelectCustomerModal() {
      this.showCustomerEventModal = true;
      setTimeout(() => { this.showSelectCustomerModal = true; }, 200);
    },
    // Ferme la modale de sélection client après un délai de transition
    closeSelectCustomerModal() {
      this.showSelectCustomerModal = false;
      setTimeout(() => { this.showCustomerEventModal = true; }, 200);
    },
    resetCustomerEvent() {
      this.customerEvent = {
        name: null,
        startedAt: null,
        endedAt: null,
        customer: {
          customerId: null,
          firstname: null,
          lastname: null,
          phone: null,
          secondaryContactPhone: null,
          email: null,
        },
        category: null,
        description: null,
        recurrencePeriod: 'NO_REPEAT',
      };
      this.phoneNumber = [];
      this.phoneNumberSecondary = [];
      this.phoneNumberThird = null;
    },
    /** * REQUÊTES API * */
    // Requête API : récupère la liste des événements-clients
    async getCustomerEvents() {
      try {
        const response = await customerEventApi.getAll(this.selectedFilterCategory, this.selectedFilterAgencyId);
        this.customerEvents = response.data;
        this.customerEvents.forEach((e) => {
          e.startedAt = new Date(e.startedAt);
          e.endedAt = new Date(e.endedAt);
          if (e.customer) {
            e.customer.phone = e.phone || e.customer.phone;
            e.customer.email = e.email || e.customer.email;
          }
        });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de la récupérations des rdv.',
          hasConfirm: true,
        });
      }
    },
    // Requête API : récupère le lien du calendrier ICal de l'agence
    async copyUrlCalendar() {
      try {
        await navigator.clipboard.writeText(this.calendarUrl);
        this.copyToClipboardSucessful = true;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de la copie de l\'url du calendrier.',
          hasConfirm: true,
        });
      }
    },
    // Requête API : créé un événement-client
    async createCustomerEvent() {
      this.isLoading = true;
      try {
        await customerEventApi.create({
          ...this.customerEvent,
          customerId: this.customerEvent.customer.customerId || undefined,
          customer: undefined,
          agencyId: this.selectedFilterAgencyId || undefined,
          phone: this.phoneNumber.length ? this.phoneNumber[0] : this.customerEvent.customer.phone, // numéro du contact principal si non défini
          secondaryPhone: this.phoneNumberSecondary[0] || null,
          thirdPhone: this.phoneNumberThird || null,
          email: this.customerEvent.customer.email,
        });
        this.showCustomerEventModal = false;
        await this.getCustomerEvents();
        this.$notification.show({ text: 'Rdv ajouté avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de l\'ajout du rdv.',
          hasConfirm: true,
        });
      }
      this.isLoading = false;
    },
    // Requête API : modifie un événement-client
    async updateCustomerEvent() {
      try {
        const {
          customerEventId,
          ...customerEvent
        } = this.customerEvent;
        const response = await customerEventApi.update(
          customerEventId,
          {
            name: customerEvent.name,
            startedAt: customerEvent.startedAt,
            endedAt: customerEvent.endedAt,
            description: customerEvent.description,
            category: customerEvent.category,
            customerId: customerEvent.customer.customerId,
            phone: this.phoneNumber.length ? this.phoneNumber[0] : customerEvent.customer.phone,
            secondaryPhone: this.phoneNumberSecondary[0] || null,
            thirdPhone: this.phoneNumberThird || null,
            email: customerEvent.customer.email,
            recurrencePeriod: customerEvent.recurrencePeriod,
          },
        );

        // Met à jour l'événement sur le calendrier
        const index = this.customerEvents.findIndex((evt) => evt.customerEventId === response.data.customerEventId);
        this.$set(this.customerEvents, index, response.data);

        this.showCustomerEventModal = false;
        this.$notification.show({ text: 'Rdv mis à jour avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de la mise à jour du rdv.',
          hasConfirm: true,
        });
      }
    },
    async updateAllRecurrencesCustomerEvent() {
      if (this.isUpdateAllRecurrence) {
        try {
          const {
            customerEventId,
            ...customerEvent
          } = this.customerEvent;
          const response = await customerEventApi.updateAllRecurrences(
            customerEventId,
            {
              name: customerEvent.name,
              startedAt: customerEvent.startedAt,
              endedAt: customerEvent.endedAt,
              description: customerEvent.description,
              category: customerEvent.category,
              customerId: customerEvent.customer.customerId,
              phone: this.phoneNumber || customerEvent.customer.phone,
              email: customerEvent.customer.email,
              recurrencePeriod: customerEvent.recurrencePeriod,
            },
          );

          // Met à jour l'événement sur le calendrier
          const index = this.customerEvents.findIndex((evt) => evt.customerEventId === response.data.customerEventId);
          this.$set(this.customerEvents, index, response.data);

          this.showCustomerEventModal = false;
          this.showRecurrenceUpdateModal = false;
          this.$notification.show({ text: 'Rdv mis à jour avec succès !' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Erreur survenue lors de la mise à jour du rdv.',
            hasConfirm: true,
          });
        }
      } else {
        this.showRecurrenceUpdateModal = false;
        await this.updateCustomerEvent();
      }
    },

    // Requête API : annule un événement-client
    async cancelCustomerEvent() {
      try {
        const { customerEventId } = this.customerEvent;
        await customerEventApi.cancel(customerEventId);

        this.showCustomerEventModal = false;
        await this.getCustomerEvents();
        this.$notification.show({ text: 'Rdv annulé avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de l\'annulation du rdv.',
          hasConfirm: true,
        });
      }
    },
    async handleClickOnUpdateCustomerEvent() {
      if (this.customerEvent.recurrencePeriod === 'NO_REPEAT') {
        await this.updateCustomerEvent();
      } else {
        this.showRecurrenceUpdateModal = true;
      }
    },
    async handleClickOnDeleteCustomerEvent() {
      if (this.customerEvent.recurrencePeriod === 'NO_REPEAT') {
        await this.deleteCustomerEvent();
      } else {
        this.showRecurrenceDeleteModal = true;
      }
    },
    // Requête API : supprime un événement-client
    async deleteCustomerEvent() {
      try {
        const { customerEventId } = this.customerEvent;
        await customerEventApi.deleteOne(customerEventId);
        this.showCustomerEventModal = false;
        await this.getCustomerEvents();
        this.$notification.show({ text: 'Rdv supprimé avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la suppression du rdv.',
          hasConfirm: true,
        });
      }
    },
    async deleteCustomerEventRecurrence() {
      if (this.isDeleteAllRecurrence) {
        try {
          const { customerEventId } = this.customerEvent;
          await customerEventApi.deleteAllRecurrences(customerEventId);
          this.showCustomerEventModal = false;
          this.showRecurrenceDeleteModal = false;
          await this.getCustomerEvents();
          this.$notification.show({ text: 'Rdv supprimés avec succès !' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la suppression des rdv.',
            hasConfirm: true,
          });
        }
      } else {
        this.showRecurrenceDeleteModal = false;
        await this.deleteCustomerEvent();
      }
    },
    // Requête API : Récupère la liste des agences de la marque
    async getAgencies() {
      try {
        const response = await agencyApi.getAll();
        this.agencyOptions = response.map((agency) => ({
          label: agency.name,
          name: agency.agencyId,
        }));
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Erreur survenue lors de la récupérations des rdv.',
          hasConfirm: true,
        });
      }
    },
    // Requête API : récupère le compte utilisateur pour avoir s'il est admin
    async getMe() {
      try {
        this.me = await memberApi.getMe();
        this.isBrandAdmin = this.me.isBrandAdmin;
        // Permet de récupérer le calendrier de l'agence du membre
        if (!this.me.isBrandAdmin) {
          this.selectedFilterAgencyId = this.me.rules[0] ? this.me.rules[0].agencyId : null;
        }
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du membre',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.calendar
  .body
    @include screen
  .header
    @include header
  .filters-container
    display: flex
    gap: 16px
    margin: 16px 0
    justify-content: flex-start
    .app-select
      max-width: 200px
  .category-select
    @include customer-event-category-select
  .selected-customer
    display: flex
    flex-direction: column
    width: 100%
    &-header
      display: flex
      flex-direction: row
      justify-content: space-between
      gap: 16px
      margin-bottom: 4px
      p
        @include heading-xs
        color: $label
      a
        @include hyperlink
        font-size: $font-xs
    &-input
      border: 1px solid $line
      background-color: $background
      border-radius: $global-border-radius
      padding: 6px 16px
      min-height: 26px
      display: flex
      flex-direction: column
      justify-content: center
      > h6
        color: $primary
        font-weight: 700
        font-size: $font-xs
        line-height: 14px
      > p
        font-size: $font-xs
        line-height: 12px
        padding-left: 12px
        position: relative
        display: flex
        align-items: center
        &::before
          content: ''
          width: 6px
          height: 6px
          border-radius: 50%
          display: block
          position: absolute
          left: 0
          top: calc(50% - 3px)
          background-color: black
      .value-undefined
        display: none
      .value-FREE::before
        background-color: $category-free
      .value-FREE_WEEKLY_MEETING::before
        background-color: $category-free-weekly-meeting
      .value-FREE_CUSTOMER_REMINDER::before
        background-color: $category-free-customer-reminder
      .value-FREE_UPDATE_ADS::before
        background-color: $category-free-update-ads
      .value-R1::before
        background-color: $category-r1
      .value-R1_PHONE::before
        background-color: $category-r1-phone
      .value-R2::before
        background-color: $category-r2
      .value-R3::before
        background-color: $category-r3
      .value-R4::before
        background-color: $category-r4
      .value-LAND_VISIT::before
        background-color: $category-land-visit
      .value-CONTRACT_SIGNATURE::before
        background-color: $category-contract-signature
      .value-PURCHASE_AGREEMENT::before
        background-color: $category-purchase-agreement
      .value-MAP::before
        background-color: $category-map
  .app-calendar
    .fc-event
      &.free
        border: $category-free
        background: $category-free
      &.free-weekly-meeting
        border: $category-free-weekly-meeting
        background: $category-free-weekly-meeting
      &.free-customer-reminder
        border: $category-free-customer-reminder
        background: $category-free-customer-reminder
      &.free
        border: $category-free-update-ads
        background: $category-free-update-ads
      &.r1
        border: $category-r1
        background: $category-r1
      &.r1-phone
        border: $category-r1-phone
        background: $category-r1-phone
      &.r2
        border: $category-r2
        background: $category-r2
      &.r3
        border: $category-r3
        background: $category-r3
      &.r4
        border: $category-r4
        background: $category-r4
      &.land-visit
        border: $category-land-visit
        background: $category-land-visit
      &.contract-signature
        border: $category-contract-signature
        background: $category-contract-signature
      &.purchase-agreement
        border: $category-purchase-agreement
        background: $category-purchase-agreement
      &.map
        border: $category-map
        background: $category-map
      &.canceled
        border: $line
        background: $line
        text-decoration: line-through darken($label, 10%)
        > .fc-content span
          color: $label
    .fc-list-item
      &.free .fc-event-dot
        background: $category-free
      &.free-weekly-meeting .fc-event-dot
        background: $category-free-weekly-meeting
      &.free-customer-reminder .fc-event-dot
        background: $category-free-customer-reminder
      &.free-update-ads .fc-event-dot
        background: $category-free-update-ads
      &.r1 .fc-event-dot
        background: $category-r1
      &.r1-phone .fc-event-dot
        background: $category-r1-phone
      &.r2 .fc-event-dot
        background: $category-r2
      &.r3 .fc-event-dot
        background: $category-r3
      &.r4 .fc-event-dot
        background: $category-r4
      &.land-visit .fc-event-dot
        background: $category-land-visit
      &.contract-signature .fc-event-dot
        background: $category-contract-signature
      &.purchase-agreement .fc-event-dot
        background: $category-purchase-agreement
      &.map .fc-event-dot
        background: $category-map
      &.canceled .fc-event-dot
        background: $line
  .modal-wrapper
    form
      display: flex
      flex-direction: column
      gap: 16px
      align-items: flex-start
      .contact
        width: 100%
        .new-contact
          display: flex
          flex-direction: column
          align-items: flex-start
          margin-top: 4px
          > a
            @include hyperlink
      .form-row
        display: flex
        gap: 16px
        justify-content: stretch
        width: 100%
        .email
          margin-top: 8px
        .phone
          width: 100%
          line-height: 2
          .phone-label
            @include label
        .date-container
          width: 100%
          display: flex
          gap: 8px
          align-items: flex-end
          .app-datepicker
            width: 100%
      .cta-containers
        margin: 16px 0 0 0
        width: 100%
        display: flex
        justify-content: center
        gap: 16px
  .recurrence-modal
    .buttons-container
      display: flex
      justify-content: center
      gap: 16px
      margin-top: 16px
</style>
