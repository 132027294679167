<template>
  <div class="version-surfaces hedifys-21">
    <form ref="version-surfaces" @submit.prevent="save">
      <!-- BREADCRUMBS -->
      <div class="breadcrumbs grid-x row">
        <div class="cell auto">
          <breadcrumb
            :items="[
              { route: { name: 'versions'}, name: 'Versions'},
              { route: { name: 'versions-versionId', params: { agencyId: $route.params.agencyId }}, name: version ? version.reference : ''}
            ]"
          />
        </div>
      </div>
      <!-- HEADER / TITRE -->
      <div class="header grid-x">
        <div class="cell auto">
          <h1>{{ version ? version.reference : '' }}</h1>
        </div>
        <div class="cell shrink save">
          <app-button theme="primary" size="large" type="submit">Sauvegarder</app-button>
        </div>
      </div>
      <!-- TABS -->
      <tab-nav :tabs="tabs" />
      <!-- BODY / FORMULAIRE -->
      <div class="body">
        <div class="row">
          <h2>Configurer vos surfaces</h2>
          <p>Sélectionnez le niveau, ajoutez lui des pièces et spécifiez leurs surfaces.</p>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-4">
            <div class="card">
              <h3>Surfaces</h3>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto">
                  <app-input v-model="habitableSurface" label="Surface habitable" placeholder="70,50" unit="m2" disabled />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto">
                  <app-input v-model="nonHabitableSurface" label="Surface annexe" placeholder="10" unit="m2" disabled />
                </div>
              </div>
              <div class="grid-x grid-margin-x row">
                <div class="cell auto">
                  <app-input v-model="totalSurface" label="Surface totale" placeholder="88,50" unit="m2" disabled />
                </div>
              </div>
              <h3>Niveaux</h3>
              <div v-if="floors && floors.length" class="grid-x grid-margin-x row">
                <div class="cell auto">
                  <div v-for="floor in floors" :key="floor.floorId" class="checkbox-container">
                    <app-checkbox :value="floor.floorId" v-model="versionFloors" @input="floor.floorId === ROOFSPACE_FLOOR_ID ? selectFloor() : null"><p>{{ floor.name }}</p></app-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cell auto">
            <div class="card" v-for="floorId in versionFloors" :key="floorId">
              <div class="grid-x grid-margin-x row floor-header">
                <h3 class="cell auto">{{ floors.find(f => f.floorId ===floorId).name }}</h3>
                <div class="cell shrink">
                  <app-button theme="primary" icon="add" @click="openRoomsModal(floorId)" :disabled="floorId === ROOFSPACE_FLOOR_ID">Ajouter des pièces</app-button>
                </div>
              </div>
              <div
                class="grid-x grid-margin-x row"
                v-if="versionSurfaces.filter(v => v.floorId === floorId) && versionSurfaces.filter(v => v.floorId === floorId).length">
                <div class="cell auto">
                  <app-table
                    :headers="tableHeader"
                    :data="versionSurfaces.filter(v => v.floorId === floorId)"
                    small>
                    <template slot="loading">
                      <app-spinner />
                    </template>
                    <template slot="icon" slot-scope="{ data }">
                      <img :src="data.room.icon" alt="icon" class="room-icon"/>
                    </template>
                    <template slot="name" slot-scope="{ data }">
                      <strong>{{ data.room.name }}</strong>
                    </template>
                    <template slot="type" slot-scope="{ data }">
                      <p>{{ roomTypes.find(t => t.name === data.room.type).label }}</p>
                    </template>
                    <template slot="area" slot-scope="{ data }">
                      <app-input v-model.number="data.area" placeholder="Surface" unit="m2" type="number" :step="0.01" :min="0" :disabled="data.room.roomId === ROOFSPACE_ROOM_ID" />
                    </template>
                    <template slot="delete" slot-scope="{ data }">
                      <app-button @click="removeSurface(data)" theme='warning' icon='remove' size='round-small'/>
                    </template>
                    <template slot="empty-table">
                      <p>Aucune donnée de disponible</p>
                    </template>
                  </app-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <app-modal class="rooms-modal" :show="isRoomsModalOpen" size="large" title="Ajouter des pièces" @update:show="isRoomsModalOpen = false">
      <form ref="rooms" @submit.prevent="addRooms">
        <div class="grid-x grid-margin-x row">
          <div class="cell auto">
            <app-search v-model="search" />
          </div>
        </div>
        <div class="grid-x grid-margin-x row" v-if="modalRooms && modalRooms.length && modalFloor">
          <div class="cell auto">
            <app-table class="rooms" :headers="tableHeaderModale" :data="modalRooms" small>
              <template slot="loading">
                <app-spinner />
              </template>
              <template slot="checkbox" slot-scope="{ data }">
                <app-checkbox :value="data.roomId" v-model="selectedRooms" ></app-checkbox>
              </template>
              <template slot="icon" slot-scope="{ data }">
                <img :src="data.icon" alt="icon" class="room-icon"/>
              </template>
              <template slot="name" slot-scope="{ data }">
                <strong>{{ data.name }}</strong>
              </template>
              <template slot="type" slot-scope="{ data }">
                <p>{{ roomTypes.find(t => t.name === data.type).label }}</p>
              </template>
            </app-table>
          </div>
        </div>
        <div v-else class="grid-x grid-margin-x row">
          <div class="cell auto">
            <p>Aucune pièce n'est disponible</p>
          </div>
        </div>
        <div class="grid-x grid-margin-x" v-if="modalRooms && modalRooms.length">
          <div class="cell auto"/>
          <div class='cell shrink'>
            <app-button theme="primary" size="large" icon="edit" type="submit">Ajouter les pièces</app-button>
          </div>
          <div class="cell auto"/>
        </div>
      </form>
    </app-modal>
  </div>
</template>
<script>
import TabNav from '@/components/layouts/TabNav.vue';
import Breadcrumb from '@/components/layouts/Breadcrumb.vue';
import roomTypes from '@/services/data/roomTypes.json';
import utils from '@/services/utils/utils';

import versionApi from '@/services/api/version';
import floorApi from '@/services/api/floor';
import roomApi from '@/services/api/room';
import surfaceApi from '@/services/api/surface';

import { create, all } from 'mathjs';

export default {
  name: 'version-surfaces',
  components: {
    TabNav,
    Breadcrumb,
  },
  metaInfo() {
    return {
      title: this.version ? `${this.version.reference} – Version` : 'Version',
    };
  },
  data() {
    return {
      roomTypes,
      tabs: [
        {
          route: {
            name: 'versions-versionId-summary',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Fiche',
        },
        {
          route: {
            name: 'versions-versionId',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Informations',
        },
        {
          route: {
            name: 'versions-versionId-medias',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Médias',
        },
        {
          route: {
            name: 'versions-versionId-surfaces',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Surfaces',
        },
        {
          route: {
            name: 'versions-versionId-extensions',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Agrandissements',
        },
        {
          route: {
            name: 'versions-versionId-lots-surveys',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Lots',
        },
        {
          route: {
            name: 'versions-versionId-variables',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Options',
        },
        {
          route: {
            name: 'versions-versionId-constraints',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Contraintes',
        },
        {
          route: {
            name: 'versions-versionId-prices',
            params: { versionId: this.$route.params.versionId },
          },
          label: 'Prix',
        },
      ],
      isLoading: null,
      tableHeader: [
        { label: 'Pièce', key: 'icon', size: 1 },
        { label: '', key: 'name', size: 3 },
        { label: 'Type', key: 'type', size: 3 },
        { label: 'Surface', key: 'area', size: 'auto' },
        { label: '', key: 'delete', size: 'shrink' },
      ],

      tableHeaderModale: [
        { label: '', key: 'checkbox', size: 1 },
        { label: 'Pièce', key: 'icon', size: 1 },
        { label: '', key: 'name', size: 5 },
        { label: 'Type', key: 'type', size: 'auto' },
      ],

      version: null,

      rooms: null,
      floors: null,

      versionFloors: null,
      versionSurfaces: null,

      selectedRooms: null,
      modalRooms: null,
      modalFloor: null,
      search: null,

      isRoomsModalOpen: false,
      mathjs: null,

      ROOFSPACE_ROOM_ID: '5f4efd93-e265-414b-8f51-c71ee1c4c859',
      ROOFSPACE_FLOOR_ID: 'c166aea2-c111-4ed6-97bf-0ec3697c9cf2',
    };
  },
  computed: {
    habitableSurface() {
      return this.versionSurfaces && this.versionSurfaces.length
        ? this.versionSurfaces.reduce((a, s) => (s.room.type === 'HABITABLE' ? this.mathjs.format(this.mathjs.add(a, s.area), { precision: 2, notation: 'fixed' }) : a), 0) : 0;
    },
    nonHabitableSurface() {
      return this.versionSurfaces && this.versionSurfaces.length
        ? this.versionSurfaces.reduce((a, s) => (s.room.type === 'NON_HABITABLE' ? this.mathjs.format(this.mathjs.add(a, s.area), { precision: 2, notation: 'fixed' }) : a), 0) : 0;
    },
    totalSurface() {
      return this.versionSurfaces && this.versionSurfaces.length
        ? this.versionSurfaces.reduce((a, s) => this.mathjs.format(this.mathjs.add(a, s.area), { precision: 2, notation: 'fixed' }), 0) : 0;
    },
  },
  mounted() {
    this.mathjs = create(all);
    this.mathjs.config({ number: 'number', precision: 14 });

    this.getData();
  },
  created() {
    this.debouncedUpdateRoomsList = utils.debounce(this.updateRoomsList, 500);
  },
  watch: {
    search() {
      this.debouncedUpdateRoomsList();
    },
  },
  methods: {
    updateRoomsList() {
      const fullRoomsList = this.rooms.filter((r) => !this.versionSurfaces.find((s) => s.room.roomId === r.roomId));
      this.modalRooms = fullRoomsList.filter((r) => r.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    async getData() {
      this.isLoading = true;
      try {
        if (this.$route.params.versionId) {
          this.version = await versionApi.getById(this.$route.params.versionId);
          this.rooms = await roomApi.getAll();
          this.floors = await floorApi.getAll();
          this.versionSurfaces = await surfaceApi.getByVersion(this.$route.params.versionId);

          this.versionFloors = this.versionSurfaces.reduce((a, s) => {
            const match = a && a.length ? a.find((floorId) => floorId === s.floorId) : null;
            if (!match) {
              a.push(s.floorId);
            }
            return a;
          }, []);
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des données',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    removeSurface(surface) {
      const surfaceIndex = this.versionSurfaces.findIndex((s) => s.room.roomId === surface.room.roomId);
      this.versionSurfaces.splice(surfaceIndex, 1);
    },
    openRoomsModal(floorId) {
      this.modalFloor = floorId;
      const modalRooms = this.rooms.filter((r) => !this.versionSurfaces.find((s) => s.room.roomId === r.roomId));
      this.modalRooms = modalRooms && modalRooms.length ? modalRooms.sort((a, b) => a.rank - b.rank) : [];
      this.selectedRooms = null;

      this.isRoomsModalOpen = true;
    },
    addRooms() {
      if (this.selectedRooms && this.selectedRooms.length) {
        let newSurfaces = this.selectedRooms.map((selectedRoom) => ({
          room: this.rooms.find((r) => selectedRoom === r.roomId),
          floorId: this.modalFloor,
          area: 0,
        }));

        if (newSurfaces && newSurfaces.length) {
          newSurfaces = [].concat([...newSurfaces, ...this.versionSurfaces]);
          newSurfaces = newSurfaces.sort((a, b) => a.room.rank - b.room.rank);
        }

        this.versionSurfaces = newSurfaces;

        this.modalFloor = null;
        this.modalRooms = null;

        this.isRoomsModalOpen = false;
      }
    },
    closeRoomsModal() {
      this.isRoomsModalOpen = false;
    },
    async save() {
      this.isLoading = true;
      try {
        if (this.$route.params.versionId) {
          const parsedSurfaces = this.versionSurfaces.map((s) => ({
            roomId: s.room.roomId,
            floorId: s.floorId,
            area: s.area,
          }));

          await surfaceApi.updateByVersion(this.$route.params.versionId, { surfaces: parsedSurfaces });
          this.versionSurfaces = await surfaceApi.getByVersion(this.$route.params.versionId);

          this.$notification.show({ text: 'Les surfaces ont bien été mises à jour !' });
        }
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la sauvegarde de la version',
          cancelText: 'Ok',
          hasCancel: true,
        });
      }
      this.isLoading = false;
    },
    selectFloor() {
      if (this.versionFloors.includes(this.ROOFSPACE_FLOOR_ID)) {
        // Si on sélectionne le niveau "combles aménageables", alors on ajoute la pièce "combles aménageables" à la liste des pièces
        const newRoom = {
          room: this.rooms.find((r) => this.ROOFSPACE_ROOM_ID === r.roomId),
          floorId: this.ROOFSPACE_FLOOR_ID,
          area: 0,
        };

        let newSurfaces = [].concat([newRoom, ...this.versionSurfaces]);
        newSurfaces = newSurfaces.sort((a, b) => a.room.rank - b.room.rank);

        this.versionSurfaces = newSurfaces;
      } else {
        // Si on supprime le niveau "combles aménageables", alors on supprime la pièce "combles aménageables" de la liste des pièces
        this.versionSurfaces = this.versionSurfaces.filter((s) => s.room.roomId !== this.ROOFSPACE_ROOM_ID);
      }
    },
  },
};
</script>

<style lang='sass'>
.version-surfaces
  .body
    @include screen
  .header
    @include header
    padding-bottom: 4px
  .row
    @include row
  .breadcrumbs
    @include breadcrumbs
  .card
    @include card
  h2
    margin-bottom: 10px !important
  .save
    margin-left: 16px
  .checkbox-container
    margin-bottom: 8px
  .app-table .data.auto
    height: initial
    flex: initial
  .floor-header
    h3
      margin-left: 1rem
      margin-right: 1rem
      margin-top: auto
      margin-bottom: auto
  .rooms-modal .app-search.large
    max-width: none
  .rooms-modal .rooms
    max-height: 50vh
</style>
