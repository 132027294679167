var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"land-prices hedifys-21"},[_vm._m(0),_c('div',{staticClass:"body"},[_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell auto search"},[_c('app-search',{attrs:{"placeholder":"Rechercher une commune par nom ou code postal","size":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"cell auto"},[(_vm.cityLandPrices)?_c('span',{staticClass:"cities-info"},[_vm._v(_vm._s(_vm.cityLandPrices.length)+" communes")]):_vm._e()]),(_vm.isBrandAdmin && _vm.agencies)?_c('div',{staticClass:"cell small-2 search"},[_c('app-select',{attrs:{"value":null,"options":_vm.agencies},on:{"input":_vm.agencyChange},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}})],1):_vm._e()]),(_vm.cityLandPrices)?_c('div',[_c('app-table',{attrs:{"headers":_vm.tableHeader,"data":_vm.cityLandPrices.filter(function (cityLandPrice) { return _vm.search === null ? true : cityLandPrice.city.toLowerCase().includes(_vm.search.toLowerCase()) || cityLandPrice.postalCodeInseeNumber.split('-')[0].includes(_vm.search); }),"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"city",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(" "+_vm._s(data.city)+" ")]),_c('br'),(data.secondaryCityName)?_c('small',[_vm._v("("+_vm._s(data.secondaryCityName.join(', '))+")")]):_vm._e()]}},{key:"postalCode",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(" "+_vm._s(data.postalCodeInseeNumber.split('-')[0])+" ")])]}},{key:"inseeNumber",fn:function(ref){
var data = ref.data;
return [_c('strong',[_vm._v(" "+_vm._s(data.postalCodeInseeNumber.split('-')[1])+" ")])]}},{key:"price",fn:function(ref){
var data = ref.data;
return [_c('app-input',{attrs:{"type":"number","disabled":data.postalCodeInseeNumber !== _vm.editableLandPriceCode},model:{value:(data.price),callback:function ($$v) {_vm.$set(data, "price", $$v)},expression:"data.price"}})]}},{key:"action",fn:function(ref){
var data = ref.data;
return [(data.postalCodeInseeNumber !== _vm.editableLandPriceCode)?_c('app-button',{attrs:{"theme":"secondary","size":"small"},on:{"click":function($event){_vm.editableLandPriceCode = data.postalCodeInseeNumber}}},[_vm._v("Modifier")]):_vm._e(),(data.postalCodeInseeNumber === _vm.editableLandPriceCode)?_c('div',{staticClass:"actions"},[_c('app-button',{attrs:{"theme":"primary","size":"small"},on:{"click":function($event){return _vm.update(data.postalCodeInseeNumber, data.price)}}},[_vm._v("Enregistrer")]),_c('app-button',{attrs:{"theme":"secondary","size":"small"},on:{"click":function($event){_vm.editableLandPriceCode = null, data.price = _vm.formatPrice(data.landToBuildAveragePrice)}}},[_vm._v("Annuler")])],1):_vm._e()]}}],null,false,3575504313)},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("Aucune donnée de disponible")])])],2)],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header grid-x"},[_c('div',{staticClass:"cell auto"},[_c('h1',[_vm._v("Prix Moyen des Terrains par Ville")])]),_c('div',{staticClass:"cell shrink"})])}]

export { render, staticRenderFns }